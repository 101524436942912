import { createGettext } from 'vue3-gettext';
import fyzionTranslations from '../language/fyzion/translations.json';
import cortexTranslations from '../language/cortex/translations.json';
const translations =
  process.env.THEME === 'cortex' ? cortexTranslations : fyzionTranslations;

const gettext = createGettext({
  availableLanguages: {
    en: 'Anglicky',
    cs: 'Česky',
    sk: 'Slovensky',
  },
  defaultLanguage: navigator.language.slice(0, 2),
  translations: translations,
  silent: true,
});

export default gettext;
