<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { isSupportedBrowser } from 'src/utils/testBrowser';
import ModalUnsupportedBrowsers from 'components/modals/ModalUnsupportedBrowsers.vue';
import { useMeta } from 'quasar';

const testBrowserError = ref<boolean>(true);

const enableSmartsupp = (): void => {
  if (process.env.ENVIRONMENT === 'localhost') {
    return;
  }
  const smartsuppScript = document.createElement('script');
  smartsuppScript.setAttribute('src', '/scripts/smartsupp.js');
  document.head.appendChild(smartsuppScript);

  if (process.env.ENVIRONMENT !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const disableSmartsuppScript = document.createElement('script');
    disableSmartsuppScript.setAttribute(
      'src',
      '/scripts/disable_recordings.js'
    );
    document.head.appendChild(disableSmartsuppScript);
  }
};
useMeta(() => ({
  title: process.env.THEME === 'cortex' ? 'Cortexapp' : 'Fyzion',
  link: {
    icon1: {
      rel: 'icon',
      type: 'image/png',
      sizes: '128x128',
      href: `icons/${process.env.THEME ?? 'fyzion'}/favicon-128x128.png`,
    },
    icon2: {
      rel: 'icon',
      type: 'image/png',
      sizes: '96x96',
      href: `icons/${process.env.THEME ?? 'fyzion'}/favicon-96x96.png`,
    },
    icon3: {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `icons/${process.env.THEME ?? 'fyzion'}/favicon-32x32.png`,
    },
    icon4: {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `icons/${process.env.THEME ?? 'fyzion'}/favicon-16x16.png`,
    },
    favicon: {
      rel: 'icon',
      type: 'image/ico',
      href: `icons/${process.env.THEME ?? 'fyzion'}/favicon.ico`,
    },
  },
}));
onMounted(() => {
  if (isSupportedBrowser()) {
    testBrowserError.value = false;
  }
  enableSmartsupp();
});
</script>
<template>
  <router-view />
  <q-dialog v-model="testBrowserError" full-width>
    <ModalUnsupportedBrowsers @close-modal="testBrowserError = false" />
  </q-dialog>
</template>
