import { defineStore } from 'pinia';
import { Notify } from 'quasar';
import {
  ReservationServiceSchema,
  ReservationUserSchema,
  SexOptions,
  TimeSlotOverviewSchema,
  TimeSlotSchema,
  VisitReservationSchema,
  ReservationCompanySchema,
  ServiceGroupSchema,
  CreateVisitReservationTimeSlotSchema,
} from 'src/api';
import { ReservationsApi } from 'src/api';
import { Errors } from 'src/utils/apiErrors';
import gettext from 'src/utils/gettext';
import type { onlineReservationPaymentTypeOptions } from 'src/components/constants';
import { useUser } from 'stores/user/useUser';

const { $gettext } = gettext;

export interface PatientDetails {
  patientFirstName: string;
  patientLastName: string;
  patientEmail: string;
  patientPhone: string;
  patientSex: SexOptions;
  patientNote: string;
  gdprConsent: boolean;
  receiveNewsletterConsent: boolean;
  vopConsent: boolean;
}

export interface FormNewReservation {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phone: string;
  sex: SexOptions | null;
  note: string;
  gdprConsent: boolean;
  receiveNewsletterConsent: boolean;
  vopConsent: boolean;
  paymentType: typeof onlineReservationPaymentTypeOptions | null;
}

interface ReservationState {
  isFetching: boolean;
  companyId: string | null;
  company: ReservationCompanySchema | null;
  user: ReservationUserSchema | null;
  service: ReservationServiceSchema | null;
  appointmentsSlotsSelected: CreateVisitReservationTimeSlotSchema[];
  visitStartTime: Date[];
  patientData: PatientDetails | null;
  reservation: VisitReservationSchema | null;
  companyUsers: ReservationUserSchema[];
  companyServices: ReservationServiceSchema[];
  companyServiceGroups: ServiceGroupSchema[];
  companyTimeSlots: TimeSlotSchema[];
  companyAvailableDays: TimeSlotOverviewSchema[];
  slotsLoading: boolean;
  anyAvailableUser: boolean;
  errors: Errors;
  formNewReservation: FormNewReservation | null;
  paymentBefore: boolean | null;
}

const ERROR_MESSAGES = {
  gdpr_consent: $gettext(
    'Pro dokončení rezervace je nutné souhlasit se zásadami zpracování osobních údajů.'
  ),
  patient_phone: $gettext('Zadané telefonní číslo je neplatné.'),
  default: $gettext('Rezervace nemohla být vytvořena'),
};

export const useReservations = defineStore({
  id: 'useReservations',
  state: (): ReservationState => {
    return {
      isFetching: false,
      companyId: null,
      service: null,
      appointmentsSlotsSelected: [],
      user: null,
      visitStartTime: [],
      company: null,
      patientData: null,
      reservation: null,
      companyUsers: [],
      companyServiceGroups: [],
      companyServices: [],
      companyTimeSlots: [],
      companyAvailableDays: [],
      slotsLoading: false,
      anyAvailableUser: false,
      errors: {},
      formNewReservation: null,
      paymentBefore: null,
    };
  },
  getters: {
    hasFourthStepAccess(): boolean {
      return !!this.reservation;
    },
    hasThirdStepAccess(): boolean {
      return (
        !!this.service &&
        !!this.user &&
        this.appointmentsSlotsSelected.length > 0
      );
    },
    hasSecondStepAccess(): boolean {
      return !!this.service;
    },
    getReservationId(): string {
      if (!this.reservation?.id) {
        console.debug('Reservation ID is not set');
        return '';
      }
      return this.reservation.id;
    },
    getCompanyId(): string {
      if (!this.companyId) {
        console.debug('Company ID is not set');
        return '';
      }
      return this.companyId;
    },
    getVisitStartTime(): Date {
      if (!this.visitStartTime) {
        console.debug('Visit start time is not set');
        return new Date(0);
      }
      return this.visitStartTime;
    },
    getServiceId(): string {
      if (!this.service?.id) {
        console.debug('Service ID is not set');
        return '';
      }
      return this.service.id;
    },
    getUserId(): string {
      if (!this.user?.id) {
        console.debug('User ID is not set');
        return '';
      }
      return this.user.id;
    },
    getUserIds(): string[] {
      if (this.user) {
        return [this.user.id ?? ''];
      }
      return this.companyUsers.map((user) => user.id ?? '');
    },
    getUserNameWithDegree(): string {
      if (this.anyAvailableUser) {
        return $gettext('Kdokoliv dostupný');
      }
      return this.user?.degree_full_name ?? '';
    },
    getVisitMultipleAppointmentsEnabled(): boolean {
      return this.service?.max_reservations_in_batch !== 1;
    },
  },
  actions: {
    addAppointmentSlot(newSlot: CreateVisitReservationTimeSlotSchema) {
      this.appointmentsSlotsSelected.push(newSlot);
      // sort; the first appointment is the earliest one
      this.appointmentsSlotsSelected.sort(
        (a, b) =>
          new Date(a.visit_start_time).getTime() -
          new Date(b.visit_start_time).getTime()
      );
    },
    setReservationFormFromPatientUser() {
      const userStore = useUser();
      let countryCode = '';
      let phone = '';

      if (userStore.patientUser?.phone) {
        const fullPhone = userStore.patientUser.phone;
        const match = fullPhone.match(/^(\+\d+)\s(.+)$/); // country code

        if (match) {
          countryCode = match[1];
          phone = match[2];
        }
      }

      this.formNewReservation = {
        firstName: userStore.patientUser?.first_name || '',
        lastName: userStore.patientUser?.last_name || '',
        email: userStore.patientUser?.email || '',
        countryCode: countryCode,
        phone: phone,
        sex: null,
        note: '',
        gdprConsent: false,
        receiveNewsletterConsent: false,
        vopConsent: false,
        paymentType: null,
      };
    },

    setService(service: ReservationServiceSchema) {
      this.service = service;
    },

    setUser(user?: ReservationUserSchema) {
      this.user = user ?? null;
    },

    setCompanyId(companyId: string) {
      this.companyId = companyId;
    },

    setPatientDetails(patientDetails: PatientDetails) {
      this.patientData = patientDetails;
    },

    setVisitStartTime(visitDate: Date) {
      this.visitStartTime = visitDate;
    },

    async createReservation() {
      if (
        !this.service ||
        !this.user ||
        !this.appointmentsSlotsSelected.length ||
        !this.companyId ||
        !this.patientData
      ) {
        Notify.create({
          message: $gettext('Některé z povinných údajů chybí'),
          color: 'negative',
          icon: 'fa-solid fa-circle-exclamation',
          timeout: 1000,
        });
        return;
      }
      await ReservationsApi.reservationsCreateVisitReservationPost({
        requestBody: {
          company_id: this.getCompanyId,
          service_id: this.getServiceId,
          time_slots: this.appointmentsSlotsSelected,
          patient_email: this.patientData.patientEmail,
          patient_first_name: this.patientData.patientFirstName,
          patient_last_name: this.patientData.patientLastName,
          patient_sex: this.patientData.patientSex,
          patient_phone: this.patientData.patientPhone,
          description: this.patientData.patientNote,
          gdpr_consent: this.patientData.gdprConsent,
          receive_newsletter_consent: this.patientData.receiveNewsletterConsent,
          vop_consent: this.patientData.vopConsent,
          require_payment_before: this.paymentBefore ?? false,
        },
      })
        .then((r): void => {
          this.reservation = r;
        })
        .catchApiErrors((errors) => {
          this.reservation = null;
          this.errors = errors;

          const errorMessagesList: string[] = [];

          if (errors) {
            Object.keys(errors).forEach((key) => {
              errorMessagesList.push(
                ERROR_MESSAGES[key as keyof typeof ERROR_MESSAGES] ||
                  ERROR_MESSAGES.default
              );
            });
          }

          Object.values(errors).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 5000,
            });
          });

          for (const message of errorMessagesList) {
            Notify.create({
              message: $gettext(message),
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 5000,
            });
          }
        });
    },
    async fetchCompany(companyId: string) {
      // prevent multiple calls from different components at the same page
      if (this.isFetching) return;

      this.isFetching = true;
      await ReservationsApi.reservationsGetCompanyGet({ companyId: companyId })
        .then((r) => {
          this.company = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst data'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    async fetchCompanyBySlug(slug: string) {
      await ReservationsApi.reservationsGetCompanyBasedOnSlugGet({ slug: slug })
        .then((r) => {
          this.company = r;
        })
        .catchApiErrors(async (errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst ordinaci na této adrese'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 2000,
          });
          await this.router.push({ name: 'error-not-found' });
        });
    },
    async fetchCompanyUsers(serviceId?: string | null) {
      await ReservationsApi.reservationsGetCompanyUsersGet({
        companyId: this.getCompanyId,
        serviceId: serviceId,
      })
        .then((r) => {
          this.companyUsers = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst dostupné fyzioterapeuty'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async fetchCompanyServiceGroups() {
      await ReservationsApi.reservationsGetCompanyServiceGroupsGet({
        companyId: this.getCompanyId,
      })
        .then((r) => {
          this.companyServiceGroups = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst skupiny služeb'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async handleFetchCompanyUsers(serviceId: string | null | undefined) {
      if (serviceId) {
        await this.fetchCompanyUsers(serviceId);
      }
      return this.$state.companyUsers.length
        ? this.$state.companyUsers[0].name_of_clinic ?? ''
        : '';
    },
    async fetchCompanyServices(serviceGroupId?: string) {
      await ReservationsApi.reservationsGetCompanyServicesGet({
        companyId: this.getCompanyId,
        serviceGroupId: serviceGroupId,
      })
        .then((r) => {
          this.companyServices = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst služby'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },

    // fetch available time slots on the selected day
    async fetchCompanyTimeSlots() {
      this.slotsLoading = true;
      const requestParams = {
        companyId: this.getCompanyId,
        serviceId: this.getServiceId,
        date: this.getVisitStartTime.toISOString().substring(0, 10),
        userIds: this.getUserIds,
      };

      await ReservationsApi.reservationsGetCompanyTimeSlotsGet(requestParams)
        .then((r) => {
          this.companyTimeSlots = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst volné termíny'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });

      this.slotsLoading = false;
    },

    // fetch available calendar days
    async fetchCompanyAvailableDays(date: Date) {
      const requestParams = {
        companyId: this.getCompanyId,
        serviceId: this.getServiceId,
        date: date?.toISOString().substring(0, 10),
        userIds: this.getUserIds,
      };

      await ReservationsApi.reservationsGetCompanyTimeSlotsOverviewGet(
        requestParams
      )
        .then((r) => {
          this.companyAvailableDays = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Nepodařilo se načíst volné termíny'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async GetVisitReservation(visitId: string) {
      const requestParams = {
        visitId: visitId,
      };
      await ReservationsApi.reservationsGetVisitReservationGet(requestParams)
        .then((r) => {
          this.reservation = r;
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Návštěva nenalezena'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
    async PatchVisitReservation(visitId: string, visitStartTime: string) {
      this.errors = {};
      const requestParams = {
        visit_start_time: visitStartTime,
      };
      await ReservationsApi.reservationsUpdateVisitReservationPatch({
        visitId: visitId,
        requestBody: requestParams,
      })
        .then((r) => {
          this.reservation = r;
        })
        .catchApiErrors((err) => {
          this.errors = err;
          Object.values(err).forEach((e) => {
            Notify.create({
              message: e[0],
              color: 'negative',
              icon: 'fa-solid fa-circle-exclamation',
              timeout: 1000,
            });
          });
        });
    },
    async CancelVisitReservation() {
      if (!this.reservation) {
        Notify.create({
          message: $gettext('Rezervaci se nepodařilo zrušit'),
          color: 'negative',
          icon: 'fa-solid fa-circle-exclamation',
          timeout: 1000,
        });
        return;
      }
      const requestParams = {
        visitId: this.getReservationId,
      };
      await ReservationsApi.reservationsDeleteVisitReservationDelete(
        requestParams
      )
        .then(() => {
          this.errors = {};
          Notify.create({
            message: $gettext('Rezervace byla zrušena'),
            color: 'positive',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        })
        .catchApiErrors((errors) => {
          this.errors = errors;
          Notify.create({
            message: $gettext('Rezervaci se nepodařilo zrušit'),
            color: 'negative',
            icon: 'fa-solid fa-circle-exclamation',
            timeout: 1000,
          });
        });
    },
  },
});
